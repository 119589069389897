export const actions = {
	onBeforeMount: [
		'loadProductTypes',
		'setAvailableYears'
	],
	onMounted: [
		'startWidthListener',
		'print/initPrintingListeners'
	]
}
