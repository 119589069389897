import { ActionTree } from 'vuex'
import { fetchNextProductImage } from '../services/fetchNextProductImage'
import { RootState } from '@/app/providers/store/types/RootState'
import { ProductCarouselMutations } from './mutations'
import { ProductCarouselSchema } from '../types/product-carousel'

interface getNextProductImageByIdOptions {
	id: string,
	count?: number,
	needLoading?: boolean
}

export const actions: ActionTree<ProductCarouselSchema, RootState> = {
	async getNextProductImageById({ commit, state }, options: getNextProductImageByIdOptions) {
		const {
			id,
			count = 1,
			needLoading = false
		} = options

		if (state.productId && state.productId !== id) {
			commit(ProductCarouselMutations.CLEAR_IMAGES)
		}

		commit(ProductCarouselMutations.SET_PRODUCT_ID, id)

		if (!state.noMoreImages) {
			if (needLoading) {
				commit(ProductCarouselMutations.SET_LOADING, true)
			}

			const array = []
			for (let i = 0; i < count; i++) {
				array.push(fetchNextProductImage(id, state.previousImageNumber))
				commit(ProductCarouselMutations.SET_PREVIOUS_IMAGE_NUMBER, state.previousImageNumber + 1)
			}

			await Promise.all(array).then(images => {
				images.forEach(image => {
					if (image) {
						commit(ProductCarouselMutations.SET_NEXT_IMAGE, image)
					} else {
						commit(ProductCarouselMutations.SET_NO_MORE_IMAGES, true)
						commit(ProductCarouselMutations.SET_TOTAL, state.images.length)
						commit(ProductCarouselMutations.SET_ALLOW_RIGHT, false)
					}
				})
			}).catch((error) => {
				console.error(error)
			}).finally(() => {
				commit(ProductCarouselMutations.SET_LOADING, false)
			})
		}
	},

	navigateToPrev({ commit, state, dispatch }) {
		if (state.allowLeft) {
			commit(ProductCarouselMutations.SET_CURRENT_IMAGE_INDEX, state.currentImageIndex - 1)

			if (state.currentPosition === state.currentImageIndex + 1) {
				commit(ProductCarouselMutations.DECREMENT_POSITION)
			}
		}

		dispatch('checkDirectionsIsAllowed')
	},

	async navigateToNext({ commit, state, dispatch }, id: string) {
		const rightIndexPosition = state.currentPosition + state.displayItemCount

		if (rightIndexPosition === state.currentImageIndex + 1) {
			if (state.images.length === rightIndexPosition) {
				const options: getNextProductImageByIdOptions = {
					id,
					needLoading: true
				}
				await dispatch('getNextProductImageById', options)
			}

			if (state.allowRight) {
				commit(ProductCarouselMutations.INCREMENT_POSITION)
			}
		}

		if (state.allowRight) {
			commit(ProductCarouselMutations.SET_CURRENT_IMAGE_INDEX, state.currentImageIndex + 1)
		}

		dispatch('checkDirectionsIsAllowed')
	},

	checkDirectionsIsAllowed({ state, commit }) {
		// Check LEFT
		commit(ProductCarouselMutations.SET_ALLOW_LEFT, state.currentImageIndex > 0)

		// Check RIGHT
		const notAllImagesHaveBeenLoaded = state.currentPosition + state.displayItemCount !== state.imagesTotal
		commit(ProductCarouselMutations.SET_ALLOW_RIGHT, notAllImagesHaveBeenLoaded)
	}
}