import { rvwaModule } from './model/state'
import LandingRVWA from './ui/LandingRVWA.vue'
import type { RvwaSchema } from './model/types/rvwa'

export {
	rvwaModule,
	RvwaSchema
}


export default LandingRVWA