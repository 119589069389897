import { AvailableYear } from '../types/report'

export enum ReportViewMode {
	SLIDES = 'slides',
	ARTICLE = 'article',
}

export const availableYears: AvailableYear[] = [
	'2018',
	'2019',
	'2020',
	'2021',
	'2022',
	'2023'
]