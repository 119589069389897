
import { defineComponent } from 'vue'
// noinspection TypeScriptCheckImport
import { ArrowDown, Calendar } from '@element-plus/icons-vue'

const currentDate = new Date()
const currentYear = currentDate.getFullYear()

export default defineComponent({
	components: {
		Calendar,
		ArrowDown
	},

	data() {
		return {
			selectedYear: this.$route.params.year || currentYear,
			noDataTooltipState: false
		}
	},

	computed: {
		availableYears() {
			return this.$store.state.reports.availableYears
		}
	},

	methods: {
		handleYearSelection(year) {
			this.selectedYear = year
			this.updateUrl(year)
		},
		updateUrl(year) {
			const pathWithoutYear = this.$route.path.slice(0, this.$route.path.indexOf(this.$route.params.year))
			location.replace(pathWithoutYear + year)
		}
	}
})
