import router from '@/app/providers/router'
import { RouteRecordNormalized } from 'vue-router'

export function isVisible(target: HTMLElement | Element): boolean {
	if (!target) return
	// Все позиции элемента
	const targetPosition = {
			top: window.pageYOffset + target.getBoundingClientRect().top,
			left: window.pageXOffset + target.getBoundingClientRect().left,
			right: window.pageXOffset + target.getBoundingClientRect().right,
			bottom: window.pageYOffset + target.getBoundingClientRect().bottom
		},
		// Получаем позиции окна
		windowPosition = {
			top: window.pageYOffset,
			left: window.pageXOffset,
			right: window.pageXOffset + document.documentElement.clientWidth,
			bottom: window.pageYOffset + document.documentElement.clientHeight
		}

	return targetPosition.bottom > windowPosition.top &&
		// Если позиция нижней части элемента больше позиции верхней чайти окна, то элемент виден сверху
		targetPosition.top < windowPosition.bottom &&
		// Если позиция верхней части элемента меньше позиции нижней чайти окна, то элемент виден снизу
		targetPosition.right > windowPosition.left &&
		// Если позиция правой стороны элемента больше позиции левой части окна, то элемент виден слева
		targetPosition.left < windowPosition.right
}

export function isVisibleMiddle(target: HTMLElement | Element): boolean {
	if (!target) return
	// Все позиции элемента
	const targetPosition = {
			top: window.pageYOffset + target.getBoundingClientRect().top,
			bottom: window.pageYOffset + target.getBoundingClientRect().bottom
		},
		// Получаем центр окна
		windowMiddle = (window.pageYOffset + document.documentElement.clientHeight) - (window.innerHeight / 2)

	return targetPosition.top < windowMiddle && targetPosition.bottom > windowMiddle
}

export function scrollStop(callback: () => void = null, refresh = 32): void {
	// Setup scrolling variable
	let isScrolling

	// Listen for scroll events
	window.addEventListener('scroll', function() {
		// Clear our timeout throughout the scroll
		window.clearTimeout(isScrolling)

		// Set a timeout to run after scrolling ends
		isScrolling = setTimeout(callback, refresh)

	}, false)
}

export function scrollToTop(top = 0): void {
	window.scrollTo({
		top,
		behavior: 'smooth'
	})
}

export function scrollFullTopPage(headerBreadcrumbsHeight = 0, deleteListener = false): void {
	let scrolling = false
	const func = e => {
		const topOffset = window.pageYOffset + e.deltaY
		const headerHeight = 52 + headerBreadcrumbsHeight
		if (e.deltaY > 0) {
			if (topOffset < (window.innerHeight - headerHeight / 2) && !scrolling) {
				scrolling = true
				scrollToTop(window.innerHeight - headerHeight)
				setTimeout(() => {
					scrolling = false
				}, 200)
			}
		} else {
			if (topOffset < window.innerHeight / 2 && !scrolling) {
				scrollToTop()
			}
		}
	}
	if (deleteListener) {
		window.removeEventListener('wheel', func)
		console.log('remove')
	} else {
		console.log('add')
		window.addEventListener('wheel', func)
	}
}

export function redirect(route: string | RouteRecordNormalized): void {
	// noinspection JSIgnoredPromiseFromCall
	router.push(route)
}

export function getCSSVar(varName: string, target = document.documentElement): string {
	let variable: string = getComputedStyle(target).getPropertyValue('--' + varName)
	if (variable.indexOf('px') !== -1) {
		variable = variable.slice(0, variable.indexOf('px'))
	}
	return variable
}

export const random = (min: number, max: number): number => {
	return Math.floor(Math.random() * (max - min + 1)) + min
}

export function declOfNum(n: number, titles: string[]): string {
	return titles[n % 10 == 1 && n % 100 != 11 ? 0 : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20) ? 1 : 2]
}

type localesType = 'ru' | 'en'

export function getLocaleString(number: number | string, locales: localesType = 'ru'): string {
	if (typeof number === 'string') {
		return Number(number).toLocaleString(locales)
	}
	return number.toLocaleString(locales)
}

export function capitalizeFirstLetter(string) {
	return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
}
