export enum RouteNames {
	LANDING = 'Landing',
	LANDING_RVWA = 'LandingRVWA',
	DEVELOPMENT_HISTORY = 'DevelopmentHistory',
	WORKING_PRINCIPLE = 'WorkingPrinciple',
	USAGE_RULES = 'UsageRules',
	TECH = 'Tech',
	CONTACTS = 'Contacts',
	COUNTRY = 'Country',
	COUNTRY_SLIDE_MODE = 'CountrySlideMode',
	DISTRICT = 'District',
	DISTRICT_SLIDE_MODE = 'DistrictSlideMode',
	REGION = 'Region',
	REGIONS_SLIDE_MODE = 'RegionSlideMode',
	ALL_PRODUCTS_BY_TYPE = 'AllProductsByCountry',
	ALL_PRODUCTS_BY_DISTRICT = 'AllProductsByDistrict',
	ALL_PRODUCTS_BY_REGION = 'AllProductsByRegion',
	ORGANIZATION = 'Organization',
	PRODUCT = 'Product',
	ORGANIZATION_CATALOG = 'OrganizationsCatalog',
	PRODUCT_SEARCH = 'ProductsSearch',
	NOT_FOUND = 'NotFound',
}