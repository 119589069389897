import { Module } from 'vuex'
import { getters } from './getters'
import { mutations } from './mutations'
import { actions } from './actions'
import { RootState } from '@/app/providers/store/types/RootState'
import { ProductCarouselSchema } from '../types/product-carousel'

const displayItemCount = 3

export const state: ProductCarouselSchema = {
	productId: undefined,
	currentImageIndex: 0,
	images: [],
	error: '',
	isLoading: false,
	noMoreImages: false,
	previousImageNumber: 0,
	currentPosition: 0,
	allowLeft: false,
	allowRight: true,
	imagesTotal: 0,
	displayItemCount
}

const namespaced = true

export const productCarouselModule: Module<ProductCarouselSchema, RootState> = {
	namespaced,
	state,
	getters,
	actions,
	mutations
}