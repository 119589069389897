import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "df jcfe w-100" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_print_button = _resolveComponent("print-button")!
  const _component_all_production_content = _resolveComponent("all-production-content")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_ctx.modelValue)
    ? (_openBlock(), _createBlock(_component_el_dialog, {
        key: 0,
        "model-value": _ctx.modelValue,
        width: "65%",
        class: "all-production",
        center: "",
        onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close'))),
        "append-to-body": ""
      }, {
        header: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_print_button, {
              "print-custom-endpoint": _ctx.printEndpoint,
              white: ""
            }, null, 8, ["print-custom-endpoint"])
          ])
        ]),
        default: _withCtx(() => [
          _createVNode(_component_all_production_content, {
            data: _ctx.data,
            "location-name": _ctx.target.name,
            "type-of-product": _ctx.typeOfProduct,
            "activity-type": _ctx.activityType,
            range: _ctx.range,
            "max-height": "60vh"
          }, null, 8, ["data", "location-name", "type-of-product", "activity-type", "range"])
        ]),
        _: 1
      }, 8, ["model-value"]))
    : _createCommentVNode("", true)
}