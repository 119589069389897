/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export default {
	state: () => ({
		isOpened: false
	}),

	mutations: {
		setSidebarState(state, value = !state.isOpened) {
			state.isOpened = value
		}
	},

	actions: {
		checkSidebarPadding({ state }) {
			if (state.isOpened) {
				document.body.classList.add('sidebar-opened')
				document.body.classList.remove('sidebar-closed')
				// document.body.style.paddingLeft = 'var(--sidebar-opened-width)'
			} else {
				document.body.classList.remove('sidebar-opened')
				document.body.classList.add('sidebar-closed')
				// document.body.style.paddingLeft = 'var(--sidebar-width)'
			}
		},
		toggleSidebar({ commit, dispatch }): void {
			commit('setSidebarState')
			dispatch('checkSidebarPadding')
		},
		removeSidebarPadding() {
			document.body.classList.remove('sidebar-closed')
			document.body.classList.remove('sidebar-opened')
		}
	}
}