import { Commit } from 'vuex'
import router from '@/app/providers/router'
import { ReportLocalState, ReportYear } from '@/pages/Report/model/types/report'
import { ActivityName } from '@/shared/consts/common'
import { ProductType } from '@/entities/ProductTypes'
import { ReportViewMode } from '@/pages/Report/model/consts'

interface Context {
	state: ReportLocalState,
	commit: Commit
}

export default {
	state: (): ReportLocalState => ({
		viewMode: (localStorage.getItem('view-mode') as ReportViewMode) || ReportViewMode.SLIDES,
		section: '',
		subSection: '',
		availableYears: []
	}),

	mutations: {
		setAvailableYears(state: ReportLocalState, value: ReportYear[]): void {
			state.availableYears = value
		},
		setViewMode(state: ReportLocalState, value: ReportViewMode): void {
			state.viewMode = value
		},
		setSection(state: ReportLocalState, value: string): void {
			state.section = value
		}
	},

	actions: {
		setAvailableYears({ commit }: Context): void {
			const currentDate = new Date()
			const currentYear: number = +currentDate.getFullYear()
			const currentYearIndex = 5
			const result = []

			for (let i = 0; i <= currentYearIndex; i++) {
				const year = currentYear - i
				result.push({
					value: year,
					disabled: currentYear === year && currentDate.getMonth() === 0
				})
			}

			commit('setAvailableYears', result)
		},
		toggleMode({ state, commit }: Context): void {
			const route = router.currentRoute.value.path
			const oldValue = state.viewMode
			commit('setViewMode', oldValue === ReportViewMode.ARTICLE ? ReportViewMode.SLIDES : ReportViewMode.ARTICLE)
			localStorage.setItem('view-mode', state.viewMode)
			if (route.indexOf(oldValue) !== -1) {
				router.replace(route.slice(0, route.indexOf('/' + oldValue)) + '/' + state.viewMode)
			}
		},
		// checkMode({ state }: Context): void {
		// 	const route = router.currentRoute.value
		// 	const forPrint = route.query.forPrint
		// 	const otherValue = state.viewMode === ViewMode.ARTICLE ? ViewMode.SLIDES : ViewMode.ARTICLE
		// 	if (!forPrint && route.path.indexOf(otherValue) !== -1) {
		// 		router.replace(route.path.slice(0, route.path.indexOf('/' + otherValue)) + '/' + state.viewMode)
		// 	}
		// },
		checkSection({ state, commit }: Context, hash: string): void {
			const index = +hash.slice(hash.indexOf('/') + 1) || 0
			commit('setSection', index ? hash.slice(hash.indexOf('#') + 1, hash.indexOf('/')) : hash.slice(hash.indexOf('#') + 1))

			if (state.section === ActivityName.RETAIL || state.section === 'footer') {
				if (index < 2) {
					state.subSection = ProductType.ALCOHOL
				} else if (index < 4) {
					state.subSection = ProductType.ALCOHOL_WITHOUT_BEER
				} else if (index < 6) {
					state.subSection = ProductType.BEER
				} else {
					state.subSection = ProductType.WITH_ALCOHOL
				}
			} else {
				if (index < 2) {
					state.subSection = ProductType.DISTILLATES
				} else if (index < 4) {
					state.subSection = ProductType.ALCOHOL
				} else if (index < 6) {
					state.subSection = ProductType.ALCOHOL_WITHOUT_BEER
				} else if (index < 8) {
					state.subSection = ProductType.BEER
				} else {
					state.subSection = ProductType.WITH_ALCOHOL
				}
			}
		}
	}
}