
import { defineComponent } from 'vue'

export default defineComponent({
	methods: {
		toggleSpecialMode(force = false) {
			const html = document.getElementsByTagName('html')[0]
			if (force || !html.classList.contains('special')) {
				html.classList.add('special')
				localStorage.setItem('specialMode', 'true')
			} else {
				html.classList.remove('special')
				localStorage.removeItem('specialMode')
			}
		}
	},

	mounted() {
		if (localStorage.getItem('specialMode')) {
			this.toggleSpecialMode(true)
		}
	}
})
