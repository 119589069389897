<template>
	<div class="production-content">
		<h3 class="production-content__title">
			<span style="font-weight: 700;">{{ getType }}</span>:
			{{ state.productTypes.normalizeTypes[typeOfProduct]?.name }}
			<span style="font-weight: 400;font-size: 1.5rem;">({{ range.toLowerCase() }})</span>
		</h3>
		<div class="production-content__location">{{ locationName }}</div>
		<div class="production-content__subtitle">
			Виды продукции, %
		</div>
		<div
			class="production-content__main"
			:style="maxHeight && `max-height: ${maxHeight}`"
			v-if="data.length"
		>
			<div
				class="production-content__group"
				v-for="group in data"
				:key="group.id"
			>
				<div class="production-content__group-title">{{ group.name }}</div>
				<el-table
					:data="group.subgroups"
					class="production-content__table"
					:show-header="false"
					row-class-name="production-content__row"
				>
					<el-table-column
						class-name="production-content__name"
						prop="name"
						width="300"
					>
						<template #default="scope">
							{{ scope.row.subgroup?.name || scope.row.name }}
						</template>
					</el-table-column>
					<el-table-column>
						<template #default="scope">
							<el-progress
								text-inside
								:stroke-width="24"
								class="production-content__progress"
								stroke-linecap="square"
								:percentage="+scope.row.percent"
							>
                <span class="production-content__value">
                  <span class="production-content__value--big">{{ scope.row.percent }}%</span>
                  <span>
	                  ({{ getLocaleString(scope.row.amount || scope.row.value) }} дал)</span>
                </span>
							</el-progress>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<span
				v-if="typeOfProduct !== ProductType.BEER && typeOfProduct !== ProductType.ALCOHOL"
				class="production-content__value-type"
			>*данные в физическом объеме</span>
		</div>
	</div>
</template>

<script>
import { computed, defineComponent } from 'vue'
import { getLocaleString } from '@/shared/helpers'
import { useAppStore } from '@/shared/config/storeConfig/storeConfig'
import { ProductType } from '@/entities/ProductTypes'
import { reportPartsMapping } from '@/shared/consts/common'

export default defineComponent({
	methods: { getLocaleString },
	computed: {
		ProductType() {
			return ProductType
		}
	},
	props: {
		data: {
			type: Array
		},
		range: {
			type: [String, Number],
			default: () => ''
		},
		activityType: {
			type: String,
			required: true
		},
		typeOfProduct: {
			type: String,
			required: true
		},
		locationName: {
			type: String,
			default: () => ''
		},
		maxHeight: String
	},

	setup(props) {
		const getType = computed(() => {
			return reportPartsMapping[props.activityType]
		})

		const { state } = useAppStore()

		return {
			getType,
			state
		}
	}
})
</script>

<style lang="scss">
.production-content {
	text-align: left;

	&__main {
		overflow-y: scroll;
	}

	&__title {
		font-size: 1.875rem;
		font-weight: 500;
		line-height: 2.25rem;
		color: var(--primary-color);
		text-align: left;
		margin: 0;
	}

	&__subtitle {
		color: var(--secondary-text-color);
		font-size: 1.25rem;
		margin-bottom: 2.25rem;
	}

	&__value-type {
		line-height: 2rem;
		font-size: 1rem;
		opacity: 0.8;
	}

	&__location {
		color: var(--primary-color);
		font-size: 1.5rem;
		line-height: 2.25rem;
		margin-bottom: 0.5rem;
	}

	&__row:nth-child(odd) .all-production__name .cell {
		background-color: var(--light-grey-color) !important;
	}

	&__table {
		width: 100%;

		.cell {
			font-family: 'Roboto', sans-serif;
			color: var(--main-text-color);
		}

		td.el-table__cell {
			border-bottom: none !important;
		}

		.el-table__inner-wrapper:before {
			display: none;
		}
	}

	&__value {
		font-size: 1rem !important;
		color: var(--main-text-color) !important;

		&--big {
			font-weight: 700 !important;
			font-size: 1rem !important;
		}
	}

	&__progress {
		.el-progress-bar__innerText {
			position: absolute;
			left: 100%;
			top: -0.75rem;
		}

		.el-progress-bar__inner {
			position: relative;
			border: 0 solid;
			height: 0;
			line-height: 0;
			border-image-slice: 1;
			border-top-width: 1.5rem;
			border-image-source: linear-gradient(270deg, #0061D9 0%, rgba(0, 97, 217, 0.785417) 23.96%, rgba(0, 97, 217, 0.6) 100%);
			background: none;
		}

		.el-progress-bar__outer {
			background-color: transparent;
			padding-right: 15rem;
		}

		.el-progress-bar__inner,
		.el-progress-bar__outer {
			border-radius: 0;
		}

		.el-progress__text {
			min-width: 400px;
		}
	}

	&__name .cell {
		word-break: break-word !important;
		font-size: 1rem;
		padding: 0.1875rem 0.75rem !important;
		border-right: 1px solid var(--separator-color) !important;
	}

	&__group {
		margin-bottom: 4rem;
		page-break-after: auto;

		&-title {
			font-weight: 500;
			font-size: 1.25rem;
			line-height: 135%;
			color: var(--primary-color);
			margin-bottom: 0.75rem;
		}
	}
}

@media print {
	.production-content {
		border-radius: 0 !important;
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		--el-dialog-width: 100% !important;
		box-shadow: none !important;
		margin: 0 !important;

		&__name {
			font-size: 1.5rem;
		}

		header {
			padding: 0 0 0.375rem 0;
		}

		.el-dialog__body {
			padding: 0 !important;
		}

		.el-table {
			height: fit-content !important;

			.el-table__body-wrapper,
			.el-scrollbar__wrap {
				height: unset !important;
			}

			.el-progress__text {
				span {
					font-size: 1rem !important;
				}

				.production-content__value--big {
					font-size: 2rem !important;
				}
			}
		}
	}
}
</style>