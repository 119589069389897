export enum ProductType {
	ALCOHOL = 'alcohol',
	DISTILLATES = 'distillates',
	BEER = 'beer',
	ALCOHOL_WITHOUT_BEER = 'alcohol-without-beer',
	WITH_ALCOHOL = 'with-alcohol',
	WINE = 'wine'
}

export enum ProductTypeShort {
	ALCOHOL = 'ap',
	DISTILLATES = 'sd',
	BEER = 'beer',
	ALCOHOL_WITHOUT_BEER = 'ap_without_beer',
	WITH_ALCOHOL = 'ss',
	WINE = 'wine'
}

type ProductShortNamesMapping = {
	[key in ProductType]: ProductTypeShort
}

export const productShortNamesMapping: ProductShortNamesMapping = {
	[ProductType.ALCOHOL]: ProductTypeShort.ALCOHOL,
	[ProductType.BEER]: ProductTypeShort.BEER,
	[ProductType.DISTILLATES]: ProductTypeShort.DISTILLATES,
	[ProductType.WITH_ALCOHOL]: ProductTypeShort.WITH_ALCOHOL,
	[ProductType.WINE]: ProductTypeShort.WINE,
	[ProductType.ALCOHOL_WITHOUT_BEER]: ProductTypeShort.ALCOHOL_WITHOUT_BEER
}

type ProductLongNamesMapping = {
	[key in ProductTypeShort]: ProductType
}
export const productLongNamesMapping: ProductLongNamesMapping = {
	[ProductTypeShort.ALCOHOL]: ProductType.ALCOHOL,
	[ProductTypeShort.BEER]: ProductType.BEER,
	[ProductTypeShort.DISTILLATES]: ProductType.DISTILLATES,
	[ProductTypeShort.WITH_ALCOHOL]: ProductType.WITH_ALCOHOL,
	[ProductTypeShort.WINE]: ProductType.WINE,
	[ProductTypeShort.ALCOHOL_WITHOUT_BEER]: ProductType.ALCOHOL_WITHOUT_BEER
}