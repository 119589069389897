import { createApp, watch } from 'vue'
import App from '@/app/App.vue'
import router from '@/app/providers/router'
import { store } from '@/app/providers/store'

import 'normalize.css'
import '@/app/styles/vars.scss'
import 'material-icons/iconfont/round.css'
import 'material-icons/iconfont/outlined.css'
import ElementPlus from 'element-plus'
import '@/app/styles/element.scss'
import ru from 'element-plus/es/locale/lang/ru'
import 'vue-fullpage.js/dist/style.css'
import '@/shared/lib/fullpage-extentions/fullpage.scrollOverflowReset.min.js'
import '@/shared/lib/fullpage-extentions/fullpage.scrollHorizontally.min.js'
import '@/shared/lib/fullpage-extentions/fullpage.resetSliders.min.js'
import '@/shared/lib/fullpage-extentions/fullpage.fadingEffect.min.js'
import VueFullPage from 'vue-fullpage.js'
import '@/app/styles/main.scss'
import '@/app/styles/print.scss'
import '@/app/styles/adaptive.scss'
import VDevHighlight from '@/app/directives/VDevHighlight'
import { apiIsReady } from '@/shared/api/api'
import { storeKey } from '@/shared/config/storeConfig/storeConfig'
import { pinia } from '@/app/providers/store/pinia'

const app = createApp(App)
	.directive('dev-highlight', VDevHighlight)
	.use(store, storeKey)
	.use(pinia)
	.use(ElementPlus, {
		locale: ru
	})
	.use(VueFullPage)

watch(() => apiIsReady.value, (newValue) => {
	if (newValue) {
		app
			.use(router)
			.mount('#app')
	}
}, { immediate: true })

