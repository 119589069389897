import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_tooltip = _resolveComponent("el-tooltip")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_tooltip, {
      content: "Версия для слабовидящих",
      effect: "light"
    }, {
      default: _withCtx(() => [
        _createElementVNode("button", {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleSpecialMode(false))),
          class: "scale-switcher material-icons-outlined"
        }, " visibility ")
      ]),
      _: 1
    })
  ]))
}