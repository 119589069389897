import { Feature, Map, MapBrowserEvent } from 'ol'
import { hoverRegionStyle, hoverStyle } from './styles'
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import { Geometry } from 'ol/geom'
import { Ref } from 'vue'

export const hoverDistrict = (selectedList: Ref, map: Map, e: MapBrowserEvent<UIEvent>): Feature => {
	return map.forEachFeatureAtPixel(e.pixel, (feature: Feature, layer: VectorLayer<VectorSource<Geometry>>) => {
		const district = feature?.getProperties().federal_districts
		const features = layer.getSource().getFeatures()
		const districtFeatures = features.filter(f => f.getProperties().federal_districts === district)
		districtFeatures.forEach(region => {
			region.setStyle(hoverStyle)
		})
		selectedList.value = districtFeatures
		return feature
	})
}

export const hoverRegion = (selectedList: Ref, map: Map, e: MapBrowserEvent<UIEvent>): Feature => {
	return map.forEachFeatureAtPixel(e.pixel, (feature: Feature) => {
		feature.setStyle(hoverRegionStyle)
		selectedList.value = [feature]
		return feature
	})
}