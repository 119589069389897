
import { computed, defineComponent, PropType } from 'vue'
import AllProductionContent from './AllProductionContent.vue'
import { useRoute } from 'vue-router'
import { Print } from '@/features/Print'

import { productShortNamesMapping } from '@/entities/ProductTypes/model/consts'
import { ReportTarget } from '@/pages/Report'

export default defineComponent({
	props: {
		modelValue: {
			type: Boolean,
			default: () => false
		},
		activityType: {
			type: String,
			default: () => ''
		},
		range: {
			type: String,
			default: () => ''
		},
		typeOfProduct: {
			type: String,
			default: () => ''
		},
		target: Object as PropType<ReportTarget>,
		data: Object
	},

	components: { AllProductionContent, PrintButton: Print },

	setup(props) {
		const { params } = useRoute()

		const printEndpoint = computed(() => {
			const shortProductType = productShortNamesMapping[props.typeOfProduct.toString()]

			let result: string
			const locationId = params.regionId || params.districtId
			if (locationId) {
				result = `/api/pdf-reports/regional-reports/${params.year}/${props.target.type}s/${locationId}/at_${props.activityType}/${shortProductType}`
			} else {
				result = `/api/pdf-reports/regional-reports/${params.year}/country/at_${props.activityType}/${shortProductType}`
			}
			return result
		})

		return {
			printEndpoint
		}
	}
})
