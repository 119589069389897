
import { defineComponent } from 'vue'

import { fullPageKeys } from '@/shared/consts/common'

export default defineComponent({
	props: {
		navigationTooltips: {
			type: Array,
			default() {
				return []
			}
		},
		anchors: {
			type: Array,
			default() {
				return []
			}
		},
		paddingTop: {
			type: String,
			default() {
				return '6.25rem'
			}
		}
	},

	data() {
		return {
			currentIndex: 0,
			options: {
				scrollingSpeed: 750,
				anchors: this.anchors,
				navigationTooltips: this.navigationTooltips,
				paddingTop: this.paddingTop,
				licenseKey: fullPageKeys.license,
				onLeave: this.setCurrent,
				scrollOverflowReset: true,
				scrollOverflowResetKey: fullPageKeys.plugins.scrollOverflow,
				scrollHorizontally: true,
				scrollHorizontallyKey: fullPageKeys.plugins.scrollHorizontally,
				slidesNavigation: true,
				navigation: true,
				verticalCentered: true,
				autoScrolling: true,
				scrollBar: false,
				normalScrollElements: '.el-overlay-dialog'
			}
		}
	},

	methods: {
		upHandler() {
			this.$refs.fullPage.api.moveTo(1)
		},
		slideTo(section, slide = 0) {
			this.$refs.fullPage.api.moveTo(section, slide)
		},
		setCurrent(origin, destination) {
			this.currentIndex = destination.index
		},
		slideDown() {
			this.$refs.fullPage.api.moveSectionDown()
		}
	},

	mounted() {
		setTimeout(() => {
			document.body.style.paddingTop = 'unset'
		}, 10)

		this.$refs.fullPage.api.reBuild()
	},

	unmounted() {
		setTimeout(() => {
			document.body.style.paddingTop = 'var(--header-height)'
		}, 10)
	}
})
