import { ActivityType, AvailableYear } from '@/pages/Report/model/types/report'
import { productShortNamesMapping } from '@/entities/ProductTypes'
import { useReportStore } from '@/pages/Report'

export function useActivityType() {
	const reportStore = useReportStore()

	const getActivityType = (year: AvailableYear, activity: string, productType: string) => {
		if (reportStore.getIsLoading) {
			return undefined
		}
		const shortProductType = productShortNamesMapping[productType]
		return reportStore.state.activityTypesByYear[year][`at_${activity}` as ActivityType]?.find(item => {
			return item.code === shortProductType
		})
	}

	return {
		getActivityType
	}
}