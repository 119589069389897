import { districtList } from '@/shared/consts/common'

export const getDistrictById = (districtId: number) => {
	return districtList[districtId]
}
export const getRegionsListByDistrictId = (districtId: number) => {
	return districtList[districtId].regions
}
export const getRegionById = (districtId: number, regionId: number) => {
	return getRegionsListByDistrictId(districtId).find(region => region.id === regionId)
}