import { ProductImage } from '../types/product-carousel'
import { $api } from '@/shared/api/api'

export const fetchNextProductImage = async (id: string, previousImageNumber: number = null): Promise<ProductImage> => {
	const { data } = await $api.get<ProductImage>(`/api/products/${id}/images/next`, {
		params: {
			previousImageNumber: previousImageNumber
		}
	})

	return data
}