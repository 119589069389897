import { NavigationGuardNext, RouteComponent, RouteLocationNormalized, RouteRecordRaw } from 'vue-router'
import { reportsGuard, setPrintEndpoint } from './routerFunctions'
import { RouteNames } from '@/shared/config/routeConfig/routeConfig'

import Country from '@/pages/Report/ui/Country.vue'
import District from '@/pages/Report/ui/District.vue'
import Region from '@/pages/Report/ui/Region.vue'
import RegionSlideMode from '@/pages/Report/ui/RegionSlideMode.vue'
import DistrictSlideMode from '@/pages/Report/ui/DistrictSlideMode.vue'
import CountrySlideMode from '@/pages/Report/ui/CountrySlideMode.vue'

const RVWA = (): Promise<RouteComponent> => import(/* webpackChunkName: "LandingRVWA" */ '@/pages/RVWA')
const WorkingPrinciple = (): Promise<RouteComponent> => import(/* webpackChunkName: "WorkingPrinciple" */ '@/pages/WorkingPrinciple')
const UsageRules = (): Promise<RouteComponent> => import(/* webpackChunkName: "UsageRules" */ '@/pages/UsageRules')
const Tech = (): Promise<RouteComponent> => import(/* webpackChunkName: "Tech" */ '@/pages/Tech')
const Contacts = (): Promise<RouteComponent> => import(/* webpackChunkName: "Contacts" */ '@/pages/Contacts')
const AllProductsByType = (): Promise<RouteComponent> => import(/* webpackChunkName: "AllProductsByType" */ '@/pages/AllProductsByTypeForPrint')
const OrganizationsCatalog = (): Promise<RouteComponent> => import(/* webpackChunkName: "OrganizationsCatalog" */ '@/pages/OrganizationCatalog')
const ProductsSearch = (): Promise<RouteComponent> => import(/* webpackChunkName: "ProductsSearch" */ '@/pages/ProductSearch')
const NotFound = (): Promise<RouteComponent> => import(/* webpackChunkName: "NotFound" */ '@/pages/NotFound')
const Landing = (): Promise<RouteComponent> => import(/* webpackChunkName: "Landing" */ '@/pages/Landing')
const Product = (): Promise<RouteComponent> => import(/* webpackChunkName: "Product" */ '@/pages/Product')
const Organization = (): Promise<RouteComponent> => import(/* webpackChunkName: "Organization" */ '@/pages/Organization')

const routes: Array<RouteRecordRaw> = [
	{
		path: '/',
		name: RouteNames.LANDING,
		component: Landing,
		meta: {
			forceReload: true,
			withoutHeader: true
		}
	},
	{
		path: '/rvwa',
		name: RouteNames.LANDING_RVWA,
		component: RVWA
	},
	{
		path: '/working-principle',
		name: RouteNames.WORKING_PRINCIPLE,
		component: WorkingPrinciple,
		meta: {
			withoutFooter: true,
			printLogo: true,
			print: true
		},
		beforeEnter: (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): void => {
			to.params.printEndpoint = '/api/pdf-reports/work-principle'
			next()
		}
	},
	{
		path: '/usage-rules',
		name: RouteNames.USAGE_RULES,
		component: UsageRules
	},
	{
		path: '/tech',
		name: RouteNames.TECH,
		component: Tech
	},
	{
		path: '/contacts',
		name: RouteNames.CONTACTS,
		component: Contacts
	},
	{
		path: '/country/:year/article',
		name: RouteNames.COUNTRY,
		component: Country,
		meta: {
			forceReload: true,
			slideMode: true
		},
		beforeEnter: [reportsGuard, setPrintEndpoint]
	},
	{
		path: '/country/:year/slides',
		name: RouteNames.COUNTRY_SLIDE_MODE,
		component: CountrySlideMode,
		meta: {
			forceReload: true,
			slideMode: true,
			withoutFooter: true
		},
		beforeEnter: [reportsGuard, setPrintEndpoint]
	},
	{
		path: '/district/:districtId/:year/article',
		name: RouteNames.DISTRICT,
		component: District,
		meta: {
			forceReload: true,
			slideMode: true
		},
		beforeEnter: [reportsGuard, setPrintEndpoint]
	},
	{
		path: '/district/:districtId/:year/slides',
		name: RouteNames.DISTRICT_SLIDE_MODE,
		component: DistrictSlideMode,
		meta: {
			forceReload: true,
			slideMode: true,
			withoutFooter: true
		},
		beforeEnter: [reportsGuard, setPrintEndpoint]
	},
	{
		path: '/district/:districtId/region/:regionId/:year/article',
		name: RouteNames.REGION,
		component: Region,
		meta: {
			forceReload: true,
			slideMode: true
		},
		beforeEnter: [reportsGuard, setPrintEndpoint]
	},
	{
		path: '/district/:districtId/region/:regionId/:year/slides',
		name: RouteNames.REGIONS_SLIDE_MODE,
		component: RegionSlideMode,
		meta: {
			forceReload: true,
			slideMode: true,
			withoutFooter: true
		},
		beforeEnter: [reportsGuard, setPrintEndpoint]
	},
	{
		path: '/all-products-for-print/:year/country/:activityType/:productType',
		name: RouteNames.ALL_PRODUCTS_BY_TYPE,
		component: AllProductsByType,
		meta: {
			withoutHeader: true,
			withoutFooter: true,
			locationType: 'country'
		}
	},
	{
		path: '/all-products-for-print/:year/districts/:districtId/:activityType/:productType',
		name: RouteNames.ALL_PRODUCTS_BY_DISTRICT,
		component: AllProductsByType,
		meta: {
			withoutHeader: true,
			withoutFooter: true,
			locationType: 'district'
		}
	},
	{
		path: '/all-products-for-print/:year/districts/:districtId/regions/:regionId/:activityType/:productType',
		name: RouteNames.ALL_PRODUCTS_BY_REGION,
		component: AllProductsByType,
		meta: {
			withoutHeader: true,
			withoutFooter: true,
			locationType: 'region'
		}
	},
	{
		path: '/organization/:organizationId',
		name: RouteNames.ORGANIZATION,
		component: Organization,
		beforeEnter: (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): void => {
			to.params.printEndpoint = `/api/pdf-reports/organization/${to.params.organizationId}`
			next()
		}
	},
	{
		path: '/product/:productId',
		name: RouteNames.PRODUCT,
		component: Product,
		beforeEnter: (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): void => {
			to.params.printEndpoint = `/api/pdf-reports/product/${to.params.productId}`
			next()
		}
	},
	{
		path: '/organizations-catalog',
		name: RouteNames.ORGANIZATION_CATALOG,
		component: OrganizationsCatalog
	},
	{
		path: '/search-products',
		name: RouteNames.PRODUCT_SEARCH,
		component: ProductsSearch
	},
	{
		path: '/:pathMatch(.*)*',
		name: RouteNames.NOT_FOUND,
		component: NotFound
	}
]

export default routes
