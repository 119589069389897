import { MutationTree } from 'vuex'
import { RvwaMember, RvwaSchema } from '../types/rvwa'

export enum RWVAMutations {
	SET_MEMBERS = 'setMembers',
	SET_IS_LOADING = 'setIsLoading',
	SET_ERROR = 'setError',
}

export const mutations: MutationTree<RvwaSchema> = {
	[RWVAMutations.SET_MEMBERS](state, payload: RvwaMember[]) {
		state.members = payload
	},
	[RWVAMutations.SET_IS_LOADING](state, payload: boolean) {
		state.isLoading = payload
	},
	[RWVAMutations.SET_ERROR](state, payload: string) {
		state.error = payload
	}
}