import { MutationTree } from 'vuex'
import { ProductCarouselSchema, ProductImage } from '../types/product-carousel'

export enum ProductCarouselMutations {
	SET_PRODUCT_ID = 'setProductId',
	SET_LOADING = 'setLoading',
	SET_NO_MORE_IMAGES = 'setNoMoreImages',
	SET_CURRENT_IMAGE_INDEX = 'setCurrentImage',
	SET_PREVIOUS_IMAGE_NUMBER = 'setPreviousImageNumber',
	SET_NEXT_IMAGE = 'addNextImage',
	INCREMENT_POSITION = 'incrementPosition',
	DECREMENT_POSITION = 'decrementPosition',
	SET_ALLOW_LEFT = 'setAllowLeft',
	SET_ALLOW_RIGHT = 'setAllowRight',
	SET_TOTAL = 'setTotal',
	CLEAR_IMAGES = 'clearImages',
}

export const mutations: MutationTree<ProductCarouselSchema> = {
	[ProductCarouselMutations.SET_PRODUCT_ID](state, payload: string) {
		state.productId = payload
	},
	[ProductCarouselMutations.SET_LOADING](state, payload) {
		state.isLoading = payload
	},
	[ProductCarouselMutations.SET_NO_MORE_IMAGES](state, payload) {
		state.noMoreImages = payload
	},
	[ProductCarouselMutations.SET_CURRENT_IMAGE_INDEX](state, payload: number) {
		state.currentImageIndex = payload
	},
	[ProductCarouselMutations.SET_PREVIOUS_IMAGE_NUMBER](state, payload: number) {
		state.previousImageNumber = payload
	},
	[ProductCarouselMutations.SET_NEXT_IMAGE](state, payload: ProductImage) {
		state.images.push(payload)
	},
	[ProductCarouselMutations.CLEAR_IMAGES](state) {
		state.images = []
	},
	[ProductCarouselMutations.INCREMENT_POSITION](state) {
		state.currentPosition = state.currentPosition + 1
	},
	[ProductCarouselMutations.DECREMENT_POSITION](state) {
		state.currentPosition = state.currentPosition - 1
	},
	[ProductCarouselMutations.SET_ALLOW_LEFT](state, payload: boolean) {
		state.allowLeft = payload
	},
	[ProductCarouselMutations.SET_ALLOW_RIGHT](state, payload: boolean) {
		state.allowRight = payload
	},
	[ProductCarouselMutations.SET_TOTAL](state, payload: number) {
		state.imagesTotal = payload
	}
}