<!--todo Удалить компонент когда точно будет не нужен-->

<template>
	<el-popover
		:visible="modelValue"
		placement="left"
		:width="400"
		popper-class="on-boarding"
	>
		<div class="on-boarding__content">
			<img
				:src="require('@/shared/assets/icons/info-mark.svg')"
				alt="info-mark"
			>
			<div class="on-boarding__text">
				<slot></slot>
			</div>
		</div>
		<template #reference>
			<slot name="reference"></slot>
		</template>
		<span
			class="material-icons-round on-boarding__close"
			@click="$emit('close')"
		>
      close
    </span>
	</el-popover>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
	props: {
		modelValue: {
			type: Boolean || Number || String
		}
	}
})
</script>

<style lang="scss">
@use "~@/app/styles/vars.scss";

.on-boarding {
	z-index: 9999 !important;
	background-color: #EEF6FF !important;
	box-shadow: 0 0.25rem 0.3125rem rgba(0, 34, 73, 0.2), 0 1px 0.625rem rgba(0, 63, 109, 0.2), 0 0.125rem 0.25rem rgba(0, 56, 125, 0.2) !important;
	border-radius: 0.25rem !important;
	padding: 1rem 3.75rem 1rem 1.5rem !important;
	@include vars.font-condensed();
	font-size: 0.75rem;
	position: relative;

	&__content {
		display: flex;
		align-items: flex-start;
	}

	&__text {
		padding-left: 0.625rem;
		margin-left: 0.625rem;
		border-left: 1px solid var(--separator-color);
		text-align: left;
		word-break: break-word;
	}

	&__close {
		position: absolute;
		right: 0.375rem;
		top: 0.375rem;
		font-size: 1.5rem;
		cursor: pointer;
		color: var(--secondary-text-color);
		transition: var(--transition);

		&:hover {
			color: var(--main-text-color);
		}
	}
}
</style>