import { MutationTree } from 'vuex'
import { Breadcrumb, BreadcrumbsSchema } from '../types/breadcrumbs'

export enum BreadcrumbsMutations {
	SET_BREADCRUMBS = 'setBreadcrumbs',
}

export const mutations: MutationTree<BreadcrumbsSchema> = {
	[BreadcrumbsMutations.SET_BREADCRUMBS](state, payload: Breadcrumb[]) {
		state.breadcrumbsList = payload
	}
}