import { PrintSchema } from './state'
import { Module } from 'vuex'
import { mutations } from './mutations'
import { actions } from './actions'
import { RootState } from '@/app/providers/store/types/RootState'

export const state: PrintSchema = {
	printing: false,
	initialize: false
}

const namespaced = true

export const printModule: Module<PrintSchema, RootState> = {
	namespaced,
	state,
	actions,
	mutations
}