import { Directive } from 'vue'
import router from '@/app/providers/router'

const vDevHighlight: Directive = {
	mounted(el: HTMLElement) {
		if ('dev' in router.currentRoute.value.query && router.currentRoute.value.query.dev !== 'false') {
			el.style.backgroundColor = 'yellow'
		} else {
			el.remove()
		}
	}
}

export default vDevHighlight