
import { defineComponent } from 'vue'

import { ProductType } from '@/entities/ProductTypes'
import { ActivityName } from '@/shared/consts/common'

export default defineComponent({
	props: {
		list: {
			type: Array,
			required: true
		}
	},

	data() {
		return {
			tooltipValue: ''
		}
	},

	computed: {
		sidebarIsOpened() {
			return this.$store.state.sidebar.isOpened
		},
		currentSection() {
			return this.$store.state.currentSection
		}
	},

	watch: {
		'$route.hash'(newValue) {
			const hashIsContains = newValue.indexOf('/') !== -1
			let hash = newValue.slice(1)
			if (hashIsContains) {
				hash = newValue.slice(1, newValue.indexOf('/'))
			}
			this.$store.commit('changeCurrentSection', hash)
		},
		currentSection(newValue) {
			this.tooltipValue = newValue

			setTimeout(() => {
				this.tooltipValue = ''
			}, 2500)
		}
	},

	methods: {
		toggleSidebar() {
			this.$store.dispatch('toggleSidebar')

			if (this.$store.state.sidebar.isOpened) {
				this.$emit('opened')
			} else {
				this.$emit('closed')
			}
		},
		getProductTypeIndexByName(name, type) {
			let res
			const isProduction = type === ActivityName.PRODUCTION
			const isRetail = type === ActivityName.RETAIL

			if (!isRetail && name === ProductType.DISTILLATES) {
				res = 0
			} else if (name === ProductType.ALCOHOL) {
				res = isRetail ? 0 : isProduction ? 4 : 3
			} else if (name === ProductType.BEER) {
				res = isRetail ? 3 : isProduction ? 8 : 6
			} else if (name === ProductType.WITH_ALCOHOL) {
				res = isRetail ? 6 : isProduction ? 12 : 9
			}
			return res !== 0 ? '/' + res : ''
		}
	},

	mounted() {
		if (!this.sidebarIsOpened) {
			this.$store.dispatch('toggleSidebar')
		}
		this.$store.dispatch('checkSidebarPadding')
	},

	unmounted() {
		this.$store.dispatch('removeSidebarPadding')
	}
})
