import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7fd8cd01"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "sidebar__header" }
const _hoisted_2 = { class: "sidebar__links" }
const _hoisted_3 = { class: "sidebar__item-tooltip" }
const _hoisted_4 = ["src", "alt"]
const _hoisted_5 = { class: "sidebar__item-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["sidebar", { 'is-opened': _ctx.sidebarIsOpened }]),
    ref: "sidebar"
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("button", {
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleSidebar && _ctx.toggleSidebar(...args))),
        type: "button",
        class: "material-icons-round btn-clear sidebar__menu",
        "data-testid": "sidebar-btn"
      }, " menu ")
    ]),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item) => {
        return (_openBlock(), _createElementBlock("div", {
          key: JSON.stringify(item),
          class: "sidebar__item-wrapper"
        }, [
          _createVNode(_component_el_tooltip, {
            effect: "light",
            placement: "right",
            visible: !_ctx.sidebarIsOpened && (_ctx.tooltipValue === item.id),
            "popper-options": { strategy: 'fixed' }
          }, {
            content: _withCtx(() => [
              _createElementVNode("span", _hoisted_3, _toDisplayString(item.name), 1)
            ]),
            default: _withCtx(() => [
              _createVNode(_component_router_link, {
                to: item.path || { hash: `#${item.id}` },
                class: _normalizeClass([{
							'active': _ctx.currentSection === item.id,
						}, "sidebar__item"]),
                onMouseenter: ($event: any) => (_ctx.tooltipValue = item.id.toString()),
                onMouseleave: _cache[1] || (_cache[1] = ($event: any) => (_ctx.tooltipValue = '')),
                style: _normalizeStyle(!_ctx.sidebarIsOpened && !item.id && 'opacity: 0;')
              }, {
                default: _withCtx(() => [
                  _createElementVNode("img", {
                    src: require(`@/shared/assets/sidebar-icons/${item.icon}-min.svg`),
                    alt: item.icon
                  }, null, 8, _hoisted_4),
                  _createElementVNode("span", _hoisted_5, _toDisplayString(item.name), 1)
                ]),
                _: 2
              }, 1032, ["to", "class", "onMouseenter", "style"])
            ]),
            _: 2
          }, 1032, ["visible"])
        ]))
      }), 128))
    ])
  ], 2))
}