import { Commit } from 'vuex'
import { $api } from '@/shared/api/api'
import { IProductTypesLocalState } from '@/entities/ProductTypes/model/types'
import { ProductType } from '@/entities/ProductTypes'

interface Context {
	state: IProductTypesLocalState,
	commit: Commit
}

export default {
	state: (): IProductTypesLocalState => <IProductTypesLocalState>({
		originalTypes: [],
		normalizeTypes: {
			[ProductType.ALCOHOL]: {},
			[ProductType.ALCOHOL_WITHOUT_BEER]: {
				name: 'алкогольной продукции без учета напитков брожения'
			},
			[ProductType.WINE]: {},
			[ProductType.BEER]: {},
			[ProductType.DISTILLATES]: {},
			[ProductType.WITH_ALCOHOL]: {}
		}
	}),

	actions: {
		async loadProductTypes({ state, commit }: Context): Promise<void> {
			await $api
				.get('/api/products-types')
				.then(response => {
					const types = response.data
					state.originalTypes = types.map(type => {
						if (type.id === 1) {
							type.classifier = ProductType.ALCOHOL
						} else if (type.id === 2) {
							type.classifier = ProductType.DISTILLATES
						} else if (type.id === 3) {
							type.classifier = ProductType.WITH_ALCOHOL
						}
						return type
					})
				}).catch(error => {
					console.log(error)
				})

			commit('setNormalizeTypes')
		}
	},

	mutations: {
		setNormalizeTypes(state: IProductTypesLocalState): void {
			state.originalTypes.forEach(item => {
				const resultItem = {
					name: item.name,
					type: item.classifier,
					id: item.id,
					subgroups: []
				}

				item.groups.forEach(group => {
					if (group.classifier) {
						state.normalizeTypes[group.classifier] = group
					} else {
						resultItem.subgroups.push(...group.subgroups.filter(subgroup => subgroup.classifier !== 'calculated'))
					}
				})

				state.normalizeTypes[item.classifier] = resultItem
			})
		}
	}
}