import { useRoute } from 'vue-router'
import { ActivityType, AvailableYear, LocationType, ReportActivitySegment } from '../types/report'
import { ActivityName, productCategories } from '@/shared/consts/common'
import { ProductType } from '@/entities/ProductTypes'
import { computed, ComputedRef, watch } from 'vue'
import { useReportStore } from '../store'
import { availableYears } from '../consts'

interface OnlyFor {
	year: AvailableYear,
	activityType: ActivityType
}

export async function useReport(targetType: ComputedRef<LocationType>, onlyFor?: OnlyFor) {
	const route = useRoute()

	const activitySegmentList: ReportActivitySegment[] = [
		{
			id: ActivityName.PRODUCTION,
			icon: ActivityName.PRODUCTION,
			name: 'Производство',
			children: productCategories
		},
		{
			id: ActivityName.IMPORT,
			icon: ActivityName.IMPORT,
			name: 'Импорт',
			children: productCategories
		},
		{
			id: ActivityName.EXPORT,
			icon: ActivityName.EXPORT,
			name: 'Экспорт',
			children: productCategories
		},
		{
			id: ActivityName.RETAIL,
			icon: ActivityName.RETAIL,
			name: 'Розница',
			children: productCategories.filter(item => item.id !== ProductType.DISTILLATES && item.id !== ProductType.WITH_ALCOHOL)
		}
	]

	const sidebarList = [
		{
			id: 'intro',
			icon: 'intro',
			name: 'Участники'
		},
		...activitySegmentList
	]

	const anchorList = sidebarList.map(item => item.id)
	const year = computed(() => route.params.year as AvailableYear)
	const targetId = computed(() =>
		Number(route.params.regionId)
		|| Number(route.params.districtId)
		|| undefined
	) as ComputedRef<number | undefined>
	const locationsList = computed(() => reportStore.state?.commonInfo?.listOfSubLocations)
	const reportStore = useReportStore()

	const fetchData = async () => {
		const promises = []

		if (targetType.value === 'country') {
			if (onlyFor) {
				promises.push(reportStore.fetchStatisticByActivityTypeOfCountry({
					year: onlyFor.year,
					activityType: onlyFor.activityType
				}))
			} else {
				promises.push(reportStore.fetchCommonStatisticOfCountry({
					year: route.params.year as AvailableYear
				}))

				for (const key of activitySegmentList) {
					const activityType = `at_${key.id}` as ActivityType

					availableYears.forEach(year => {
						promises.push(reportStore.fetchStatisticByActivityTypeOfCountry({
							year,
							activityType
						}))
					})
				}
			}

		} else if (targetType) {

			if (onlyFor) {
				promises.push(reportStore.fetchStatisticByActivityTypeOfLocation({
					year: onlyFor.year,
					activityType: onlyFor.activityType,
					locationType: targetType.value,
					locationId: Number(route.params.regionId || route.params.districtId)
				}))
			} else {
				promises.push(reportStore.fetchCommonStatisticOfLocation({
					year: String(route.params.year) as AvailableYear,
					locationType: targetType.value,
					locationId: Number(route.params.regionId || route.params.districtId)
				}))

				for (const key of activitySegmentList) {
					const activityType = `at_${key.id}` as ActivityType
					availableYears.forEach(year => {
						promises.push(reportStore.fetchStatisticByActivityTypeOfLocation({
							year,
							activityType,
							locationType: targetType.value,
							locationId: Number(route.params.regionId || route.params.districtId)
						}))
					})
				}
			}
		}

		await Promise.all(promises)
	}

	await watch(() => targetType.value, async (newValue) => {
		if (newValue) {
			await fetchData()
		}
	}, { immediate: true })

	return {
		anchorList,
		year,
		targetId,
		reportStore,
		locationsList,
		sidebarList,
		activitySegmentList
	}
}