import { ActionTree } from 'vuex'
import { RootState } from '@/app/providers/store/types/RootState'
import { RvwaMember, RvwaSchema } from '../types/rvwa'
import { RWVAMutations } from './mutations'
import { $api } from '@/shared/api/api'

export const actions: ActionTree<RvwaSchema, RootState> = {
	async getRvwaMembersList({ commit }) {
		commit(RWVAMutations.SET_IS_LOADING, true)

		try {
			const { data } = await $api.get<RvwaMember[]>('/api/registry/avvr-organizations')

			if (data) {
				commit(RWVAMutations.SET_MEMBERS, data)
			} else {
				throw new Error('No data')
			}
		} catch (error) {
			commit(RWVAMutations.SET_ERROR, error)
			console.error(error.message)
		}

		commit(RWVAMutations.SET_IS_LOADING, false)
	}
}