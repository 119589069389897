import axios, { AxiosInstance } from 'axios'
import { Ref, ref, watch } from 'vue'
import { serverConfig } from '@/shared/config/serverConfig/serverConfig'

const baseURL: Ref<string | undefined> = ref(undefined)

const getBaseURL = async (config): Promise<string> => {
	if (!baseURL.value && config) {
		const api = config.api

		/*todo temp hack - tweak after 20.04.2023*/
		console.info('Configured env: "' + config.environment + '"')
		console.info('Configured host: "' + api.host + '"')
		if (config.environment === 'dev_machine') {
			baseURL.value = `${api.protocol}://${api.host}${api.port && ':' + api.port}`
		} else if (api.host === 'localhost') {
			console.warn('Replace API host with window.location.hostname')
			baseURL.value = '/'
		} else {
			baseURL.value = `${api.protocol}://${api.host}${api.port && ':' + api.port}`
		}
		console.info('Resulted base url: "' + baseURL.value + '"')
	}
	console.log(config)
	return baseURL.value
}

export const $api: AxiosInstance = axios.create()
export const apiIsReady: Ref<boolean> = ref(false)

watch(() => serverConfig.value, newValue => {
	if (newValue) {
		getBaseURL(newValue).then(url => {
			$api.defaults.baseURL = url
			apiIsReady.value = true
		})
	}
}, { immediate: true })