<template>
	<div
		v-if="!$route.meta.withoutFooter"
		id="footer"
	>
		<footer class="footer">
			<div class="container">
				<div class="footer__content">
					<router-link
						to="/"
						class="footer__logo"
					>
						ЕГАИС <br>
						<span>Аналитический портал</span>
					</router-link>

					<nav class="footer__nav">
            <span
							v-for="navItem in navList"
							:key="navItem.name"
							class="footer__nav-item"
							@click="redirect(navItem.path)"
						>
              {{ navItem.name }}
            </span>
					</nav>

					<div class="footer__special">
						<scale-switcher />
					</div>

					<div class="footer__footer">
          <span>
            © {{ new Date().getFullYear() }} Аналитический портал
            ЕГАИС
          </span>
						<router-link :to="{ name: 'UsageRules' }">
							Правила и&nbsp;условия использования контента сайта
						</router-link>
					</div>
				</div>
			</div>
		</footer>

		<footer class="container footer-for-print">
			<div class="footer-for-print__breadcrumbs">
				<div
					class="footer-breadcrumb"
					v-for="breadcrumb in breadcrumbsWithoutFirst"
					:key="JSON.stringify(breadcrumb)"
				>
					{{ breadcrumb.name }}
				</div>
			</div>
		</footer>
	</div>
</template>

<script>
import { ScaleSwitcher } from '@/features/ScaleSwitcher'
import { defineComponent } from 'vue'
import { redirect } from '@/shared/helpers'

export default defineComponent({
	methods: { redirect },
	components: { ScaleSwitcher },

	computed: {
		breadcrumbsWithoutFirst() {
			return this.$store.state.breadcrumbs.breadcrumbsList?.filter(breadcrumb => breadcrumb.path !== '/')
		}
	},

	data() {
		return {
			navList: [
				{
					path: '/organizations-catalog?filter=alcohol',
					name: 'Реестр производителей'
				},
				{
					path: '/country',
					name: 'Аналитика алкогольного рынка'
				},
				{
					path: '/contacts',
					name: 'Контакты'
				},
				{
					path: '/tech',
					name: 'Техническая информация'
				}
			]
		}
	}
})
</script>

<style
	lang="scss"
	scoped
>
@use '~@/app/styles/vars.scss';

.footer {
	display: flex;
	align-items: center;
	padding-top: 1.5rem;
	padding-bottom: 1.25rem;
	background-color: var(--primary-color);
	color: white;
	min-height: 7.5rem;
	height: var(--footer-height);

	& .container {
		width: 100%;
	}

	&__content {
		position: relative;
	}

	&__logo {
		cursor: pointer;
		position: absolute;
		left: 0;
		top: 0;
		text-align: left;
		text-transform: uppercase;
		font-family: "Roboto Condensed", sans-serif;
		font-size: 1.25rem;
		line-height: 1.1rem;
		font-weight: 700;
		letter-spacing: 0.03em;
		transition: var(--transition);

		span {
			font-size: 0.8rem;
			font-weight: 400;
		}

		&:hover {
			opacity: 0.7;
		}
	}

	&__nav {
		display: flex;
		justify-content: center;
		padding-bottom: 4rem;
		margin-bottom: 0.625rem;
		border-bottom: 1px solid var(--separator-color);

		&-item {
			cursor: pointer;
			font-size: 1rem;
			font-weight: 500;

			&:hover {
				text-decoration: underline;
			}

			&:not(:last-child) {
				padding-right: 1.25rem;
				margin-right: 1.25rem;
				border-right: 1px solid var(--separator-color);
			}
		}
	}

	&__special {
		position: absolute;
		right: 0;
		bottom: 2rem;
		display: flex;
		gap: 1rem;
	}

	&__footer {
		display: flex;
		justify-content: space-between;

		& span {
			font-size: 0.75rem;
		}

		& a {
			font-size: 0.75rem;

			&:hover {
				text-decoration: underline;
			}
		}
	}

	@media print {
		display: none;
	}
}

.footer-for-print {
	display: none;
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	justify-content: space-between;
	background-color: white;

	&:before {
		content: '';
		border-bottom: 1px solid var(--separator-color);
		position: absolute;
		left: var(--container-padding);
		right: var(--container-padding);
		bottom: 0.1875rem;
		z-index: 1;
	}

	&__breadcrumbs {
		display: flex;
		background-color: white;
		padding-right: 0.5rem;
		z-index: 2;
		font-size: 1rem;
	}

	.footer-breadcrumb {
		white-space: nowrap;
		color: var(--primary-color);
		@include vars.font-condensed();

		&:not(:first-child) {
			padding-left: 0.75rem;
			position: relative;

			&:before {
				content: '|';
				position: absolute;
				left: 0.3125rem;
				top: 0;
				bottom: 0;
				color: var(--primary-color);
			}
		}
	}

	@media print {
		display: flex;
	}
}
</style>