
import { defineComponent, PropType } from 'vue'
import { getLocaleString, isVisibleMiddle, scrollStop } from '@/shared/helpers'
import { Sidebar } from '@/widgets/Sidebar'
import { ReportYearSelect } from '@/features/ReportYearSelect'

export default defineComponent({
	props: {
		title: {
			type: String
		},
		subtitle: {
			type: String
		},
		listenScroll: {
			type: Boolean,
			default: true
		},
		hasYearSelect: {
			type: Boolean,
			default: false
		},
		fixed: {
			type: Boolean,
			default: false
		},
		sidebarList: {
			type: Array,
			default() {
				return []
			}
		},
		population: {
			type: Number
		},
		populationDensity: {
			type: Number
		},
		paddingTop: {
			type: String,
			default: () => '0'
		},
		customLink: {
			type: Object as PropType<{ text: string, url: string }>,
			default: () => {
				return {
					text: '',
					url: ''
				}
			}
		}
	},

	components: {
		Sidebar,
		ReportYearSelect
	},

	computed: {
		normalizePopulation() {
			return getLocaleString(this.population)
		},
		showHeader() {
			return this.title || this.subtitle || this.population || this.populationDensity || this.hasYearSelect
		}
	},

	data() {
		return {
			hashList: [],
			subSectionList: [],
			sidebarOpened: false
		}
	},

	methods: {
		check() {
			this.hashList.forEach((hash) => {
				if (isVisibleMiddle(document.getElementById(hash))) {
					if (this.$store.state.currentSection !== hash) {
						this.$store.commit('changeCurrentSection', hash)
					}
				}
			})

			this.subSectionList.forEach((query) => {
				document.querySelectorAll(`[data-id="${query}"]`).forEach(node => {
					if (isVisibleMiddle(node)) {
						if (this.$store.state.currentSubSection !== query) {
							this.$store.commit('changeCurrentSubSection', query)
						}
					}
				})
			})
		}
	},

	mounted() {
		if (this.listenScroll) {
			const anchorList = document.querySelectorAll('[data-name="anchor"]')
			anchorList.forEach((anchor) => {
				this.hashList.push(anchor.getAttribute('id'))
			})

			const subAnchorList = document.querySelectorAll('[data-name="sub-anchor"]')
			subAnchorList.forEach((anchor) => {
				const id = anchor.getAttribute('data-id')
				if (!this.subSectionList.includes(id)) {
					this.subSectionList.push(id)
				}
			})

			scrollStop(this.check)
		}
	}
})
