import { createStore } from 'vuex'
import { RootState } from './types/RootState'
import router from '@/app/providers/router'
import sidebar from '@/app/providers/store/modules/sidebar'
import productTypes from '@/app/providers/store/modules/productTypes'
import productSearch from '@/app/providers/store/modules/productSearch'
import reports from '@/app/providers/store/modules/reports'
import { productCarouselModule } from '@/features/ProductCarousel'
import { printModule } from '@/features/Print'
import { rvwaModule } from '@/pages/RVWA'
import { breadcrumbsModule } from '@/features/Breadcrumbs'
import { ReportTarget } from '@/pages/Report/model/types/report'

export const store = createStore<RootState>({
	state: {
		mainData: {
			regions: [],
			type: '',
			name: ''
		},
		currentSection: '',
		currentSubSection: '',
		isLoading: false,
		windowWidth: 0
	},
	mutations: {
		changeCurrentSection(state, value: string) {
			if (state.currentSection !== value) {
				state.currentSection = value
				history.replaceState(history.state, null, `#${value}`)
			}
		},
		changeCurrentSubSection(state, value: string) {
			if (state.currentSubSection !== value) {
				state.currentSubSection = value
				history.replaceState(history.state, null, `?productType=${value}${location.hash}`)
			}
		},
		setLoading(state, value = !state.isLoading) {
			state.isLoading = value
		},
		changeWindowWidth(state, value) {
			state.windowWidth = value
		}
	},
	actions: {
		startWidthListener({ commit }) {
			commit('changeWindowWidth', window.innerWidth)

			let timeout
			window.addEventListener('resize', e => {
				window.clearTimeout(timeout)

				timeout = setTimeout(() => {
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					commit('changeWindowWidth', e.target.innerWidth)
				}, 300)
			})
		}
	},
	getters: {
		districtById: (state) => (districtId) => {
			const result: ReportTarget = districtId && state.mainData.regions.length
				? state.mainData.regions.find(district => +district.id === +districtId)
				: {}

			result.type = 'district'
			return result
		},
		regionById: (state, getters) => (districtId, regionId) => {
			const result: ReportTarget = districtId && regionId && state.mainData.regions.length
				? getters.districtById(districtId).regions?.find(region => +region.id === +regionId)
				: {}

			result.type = 'region'
			return result
		},
		dropdownDistricts: (state) => (id) => {
			if (state.mainData.regions.length) {
				const array = []
				state.mainData.regions.forEach(region => {
					if (+region.id !== +id) {
						array.push({
							name: region.name,
							path: `/district/${region.id}/${router.currentRoute.value.params.year}`
						})
					}
				})
				return array
			} else return []
		},
		dropdownRegions: (state, getters) => (districtId, regionId) => {
			const regions = getters.districtById(districtId).regions
			if (state.mainData.regions.length && regions) {
				const array = []

				regions.forEach(region => {
					if (+region.id !== +regionId) {
						array.push({
							name: region.name,
							path: `/district/${districtId}/region/${region.id}/${router.currentRoute.value.params.year}`
						})
					}
				})
				return array
			} else return []
		}
	},
	modules: {
		sidebar,
		productTypes,
		productSearch,
		reports,
		breadcrumbs: breadcrumbsModule,
		print: printModule,
		rvwa: rvwaModule,
		productCarousel: productCarouselModule
	}
})