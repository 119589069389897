import { Module } from 'vuex'
import { mutations } from './mutations'
import { actions } from './actions'
import { RootState } from '@/app/providers/store/types/RootState'
import { RvwaSchema } from '../types/rvwa'

export const state: RvwaSchema = {
	members: [],
	error: undefined,
	isLoading: false
}

const namespaced = true

export const rvwaModule: Module<RvwaSchema, RootState> = {
	namespaced,
	state,
	actions,
	mutations
}