import { computed, ComputedRef, ref, Ref } from 'vue'
import { districtList, getRegionsListByDistrictId } from '@/entities/VectorMap'
import { LocationType } from '../types/report'

export function useReportMap(targetType: ComputedRef<LocationType>, targetId: number) {
	const hoveredId: Ref<number> = ref(undefined)
	const locationsList = computed(() => {
		return targetType.value === 'district' ? getRegionsListByDistrictId(targetId) : districtList
	})

	const mapItemIsExpanded = computed(() => {
		if (typeof locationsList.value === 'object') {
			return false
		}
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		return locationsList.value.length > 12
	})

	const hoverOnFeature = (id: number) => {
		hoveredId.value = id
	}

	return {
		mapItemIsExpanded,
		hoveredId,
		locationsList,
		hoverOnFeature
	}
}