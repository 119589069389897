import { createRouter, createWebHistory } from 'vue-router'
import routes from '@/app/providers/router/routes'
import { checkHardLoadNeed, checkHardReloadNeed, checkTransferPrintEndpoint, completePathIfNeed } from '@/app/providers/router/routerFunctions'

const router = createRouter({
	history: createWebHistory(),
	routes,
	scrollBehavior(to, from, savedPosition) {
		if (to.path.indexOf('slide') !== -1 || to.name === 'WorkingPrinciple' || to.name === 'DevelopmentHistory' || to.name === 'Landing') {
			return savedPosition
		} else if (to.query.typeOfProduct && to.hash) {
			const part = document.querySelector(to.hash)
			return {
				el: part.querySelector(`[data-id="${to.query.typeOfProduct}"]`),
				behavior: 'smooth',
				top: 320
			}
		} else if (to.hash) {
			return {
				el: to.hash,
				behavior: 'smooth',
				top: 120
			}
		} else {
			return {
				top: 0
			}
		}
	}
})

router.beforeEach((to, from) => {
	const completedPath = completePathIfNeed(to)
	if (completedPath) return completedPath

	if (from.name && to.path !== from.path) {
		return checkHardLoadNeed(to)
	}
})

router.afterEach((to, from) => {
	checkTransferPrintEndpoint(to, from)

	checkHardReloadNeed(to, from)
})

export default router