import { AvailableYear, LocationType } from '../types/report'
import { Breadcrumb } from '@/features/Breadcrumbs'
import { availableYears } from '@/pages/Report/model/consts'
import { useRoute } from 'vue-router'
import { districtList } from '@/shared/consts/common'
import { DistrictObject } from '@/shared/types/common'

export const getReportYearsDropdown = (locationType: LocationType, locationId?: number, locationParentId?: number): Breadcrumb[] => {
	const locationPath = locationType === 'region'
		? `/district/${locationParentId}/region/${locationId}`
		: `/${locationType}${locationId ? '/' + locationId : ''}`

	return availableYears.map(year => ({
		path: `${locationPath}/${year}`,
		name: year
	}))
}

export const getReportBreadcrumbs = (locationType: LocationType, year: AvailableYear, locationId?: number) => {
	const route = useRoute()

	const breadcrumbs: Breadcrumb[] = [
		{ path: '/', name: 'Главная' },
		{
			path: `/country/${year}`,
			name: 'Алкогольный рынок РФ'
		}
	]

	if (locationType === 'country') {
		breadcrumbs.push({
			path: route.path,
			name: year,
			dropdown: getReportYearsDropdown(locationType)
		})
	} else if (locationType === 'district' || locationType === 'region') {
		const district: DistrictObject = districtList[route.params.districtId as string]
		const districtBreadcrumb: Breadcrumb = {
			path: `/district/${district.id}/${year}`,
			name: district.name,
			dropdown: []
		}


		Object.values(districtList).forEach((district: DistrictObject) => {
			if (district.id !== locationId) {
				districtBreadcrumb.dropdown.push({
					path: `/district/${district.id}/${year}`,
					name: district.name
				})
			}
		})

		breadcrumbs.push(districtBreadcrumb)

		if (locationType === 'region') {
			const regionBreadcrumbs: Breadcrumb = {
				path: `/district/${district.id}/region/${locationId}/${year}`,
				name: district.regions.find(region => region.id === locationId).name,
				dropdown: []
			}

			district.regions.forEach(region => {
				regionBreadcrumbs.dropdown.push({
					path: `/district/${district.id}/region/${region.id}/${year}`,
					name: region.name
				})
			})

			breadcrumbs.push(regionBreadcrumbs)
		}

		breadcrumbs.push({
			path: route.path,
			name: year,
			dropdown: getReportYearsDropdown(locationType, district.id)
		})
	}

	return breadcrumbs
}