import { defineStore } from 'pinia'
import { computed, reactive } from 'vue'
import { $api } from '@/shared/api/api'
import {
	ActivityTypesFromServer,
	FetchCommonStatisticOfCountry,
	FetchCommonStatisticOfLocation,
	FetchStatisticByActivityTypeOfCountryOptions,
	FetchStatisticByActivityTypeOfLocationOptions,
	ReportCommonInfo,
	ReportSchema
} from '../types/report'
import { availableYears } from '@/pages/Report/model/consts'

export const useReportStore = defineStore('report', () => {
	const state = reactive<ReportSchema>({
		period: '',
		commonInfo: undefined,
		activityTypesByYear: undefined,
		isLoading: false,
		error: ''
	})

	availableYears.forEach(year => {
		state.activityTypesByYear = {
			...state.activityTypesByYear,
			[year]: {
				at_import: undefined,
				at_export: undefined,
				at_retail: undefined,
				at_production: undefined
			}
		}
	})

	const getIsLoading = computed(() => state.isLoading)
	const getCommonInfo = computed(() => state.commonInfo)
	const getActivityTypes = computed(() => state.activityTypesByYear)
	const getTsUpdated = computed(() => state.commonInfo?.tsUpdate)

	const fetchCommonStatisticOfCountry = async (options: FetchCommonStatisticOfCountry) => {
		try {
			state.isLoading = true
			const { data } = await $api.get<ReportCommonInfo>(
				'/api/regional-analytic/common',
				{
					params: {
						year: options.year
					}
				})

			if (!data) {
				throw new Error('No data')
			}

			state.commonInfo = data
		} catch (error) {
			state.error = error.message
		} finally {
			state.isLoading = false
		}
	}

	const fetchCommonStatisticOfLocation = async (options: FetchCommonStatisticOfLocation) => {
		try {
			state.isLoading = true
			const { data } = await $api.get<ReportCommonInfo>(
				`/api/regional-analytic/common/${options.locationType}/${options.locationId}`,
				{
					params: {
						year: options.year
					}
				})

			if (!data) {
				throw new Error('No data')
			}

			const listOfSubLocationsWithIds = data.listOfSubLocations?.map(location => ({
				...location,
				id: Number(location.code)
			}))


			state.commonInfo = data

			if (listOfSubLocationsWithIds) {
				state.commonInfo.listOfSubLocations = listOfSubLocationsWithIds
			}

		} catch (error) {
			state.error = error.message
		} finally {
			state.isLoading = false
		}
	}

	const fetchStatisticByActivityTypeOfCountry = async (options: FetchStatisticByActivityTypeOfCountryOptions) => {
		try {
			state.isLoading = true
			const { data } = await $api.get<ActivityTypesFromServer>(
				`/api/regional-analytic/${options.activityType}`,
				{
					params: {
						year: options.year
					}
				})

			if (!data) {
				throw new Error('No data')
			}

			state.activityTypesByYear[options.year][options.activityType] = data.categories
		} catch (error) {
			state.error = error.message
		} finally {
			state.isLoading = false
		}
	}

	const fetchStatisticByActivityTypeOfLocation = async (options: FetchStatisticByActivityTypeOfLocationOptions) => {
		try {
			state.isLoading = true
			const { data } = await $api.get<ActivityTypesFromServer>(
				`/api/regional-analytic/${options.activityType}/${options.locationType}/${options.locationId}`,
				{
					params: {
						year: options.year
					}
				})

			if (!data) {
				throw new Error('No data')
			}

			state.activityTypesByYear[options.year][options.activityType] = data.categories
		} catch (error) {
			state.error = error.message
		} finally {
			state.isLoading = false
		}
	}

	return {
		state,
		getIsLoading,
		getActivityTypes,
		getCommonInfo,
		getTsUpdated,
		fetchCommonStatisticOfCountry,
		fetchCommonStatisticOfLocation,
		fetchStatisticByActivityTypeOfCountry,
		fetchStatisticByActivityTypeOfLocation
	}
})
