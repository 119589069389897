import { MutationTree } from 'vuex'
import { PrintSchema } from './state'

export enum PrintMutations {
	SET_PRINTING = 'setPrinting',
	SET_INITIALIZE = 'setInitialize',
}

export const mutations: MutationTree<PrintSchema> = {
	[PrintMutations.SET_PRINTING](state, payload) {
		state.printing = payload
	},
	[PrintMutations.SET_INITIALIZE](state, payload) {
		state.initialize = payload
	}
}