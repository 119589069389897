
import { defineComponent } from 'vue'
import { Breadcrumbs } from '@/features/Breadcrumbs'
import { ScaleSwitcher } from '@/features/ScaleSwitcher'
import { Print } from '@/features/Print'
import { ReportModeToggle } from '@/features/ReportModeToggle'

export default defineComponent({
	props: {
		transparent: {
			type: Boolean,
			default: false
		},
		fixed: {
			type: Boolean,
			default: true
		}
	},

	components: {
		ReportModeToggle,
		Print,
		ScaleSwitcher,
		Breadcrumbs
	},

	computed: {
		breadcrumbs() {
			return this.$store.state.breadcrumbs.breadcrumbsList?.length
		}
	},

	watch: {
		'$route.path': {
			handler() {
				this.checkBodyPaddingIsNeeded()
			},
			immediate: true
		}
	},

	methods: {
		checkBodyPaddingIsNeeded() {
			setTimeout(() => {
				if (this.transparent) {
					document.body.style.paddingTop = 'unset'
				} else {
					document.body.style.paddingTop = 'var(--header-height)'
				}
			}, 0)
		}
	}
})
