/* eslint-disable @typescript-eslint/ban-ts-comment */
import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import { store } from '@/app/providers/store'

import { ReportYear } from '@/pages/Report/model/types/report'

export function checkHardLoadNeed(to: RouteLocationNormalized): void | boolean {
	if (to.meta.forceReload && !localStorage.getItem('page-has-been-force-reloaded')) {
		localStorage.setItem('page-has-been-force-reloaded', 'true')
		window.location.assign(to.fullPath)
		return false
	}
}

export function checkHardReloadNeed(to: RouteLocationNormalized, from: RouteLocationNormalized): void {
	if ((to.meta.forceReload || from.meta.forceReload) && localStorage.getItem('page-has-been-force-reloaded')) {
		setTimeout(() => {
			localStorage.removeItem('page-has-been-force-reloaded')
		}, 200)
	}
}

const currentDate = new Date()
const lastAvailableYear = currentDate.getMonth() !== 0 ? currentDate.getFullYear() : currentDate.getFullYear() - 1
const pathWithYear = (to: RouteLocationNormalized) => {
	return `${to.path}/${lastAvailableYear}/${store.state.reports.viewMode}${to.hash || ''}`
}
const pathWithMode = (to: RouteLocationNormalized) => {
	return `${to.path}/${store.state.reports.viewMode}${to.hash || ''}`
}

export function completePathIfNeed(to: RouteLocationNormalized): string {
	if (to.path.match(/\/country\/\d{4}$/) || to.path.match(/\/district\/\d{1,2}\/\d{4}$/) || to.path.match(/\/district\/\d{1,2}\/region\/\d{1,2}\/\d{4}$/)) {
		return pathWithMode(to)
	} else if (to.path.match(/\/country$/) || to.path.match(/\/district\/\d{1,2}$/) || to.path.match(/\/district\/\d{1,2}\/region\/\d{1,2}$/)) {
		return pathWithYear(to)
	}
}

function isYearAvailable(year: number, availableYears: ReportYear[]): boolean {
	const yearInfo = availableYears.find(y => y.value === year)
	return yearInfo !== undefined && !yearInfo.disabled
}

interface notFountRoute {
	path: string,
	query: {
		type: string,
		year: string | number,
		district?: string | number,
		region?: string | number
	}
}

export function reportsGuard(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): void {
	if (isYearAvailable(+to.params.year, store.state.reports.availableYears)) {
		next()
	} else {
		const notFound: notFountRoute = {
			path: '/404',
			query: {
				type: 'report',
				year: +to.params.year
			}
		}

		if (to.params.districtId) {
			notFound.query.district = +to.params.districtId
		}

		if (to.params.regionId) {
			notFound.query.region = +to.params.regionId
		}

		next(notFound)
	}
}

export function checkTransferPrintEndpoint(to: RouteLocationNormalized, from: RouteLocationNormalized): void {
	if (from.params.printEndpoint && to.name === from.name) {
		to.params.printEndpoint = from.params.printEndpoint
	}
}

export function setPrintEndpoint(
	to: RouteLocationNormalized,
	from: RouteLocationNormalized,
	next: NavigationGuardNext
): void {
	if (to.name.toString().includes('Country')) {
		to.params.printEndpoint =
			`/api/pdf-reports/regional-reports/${to.params.year}/country`
	} else if (to.name.toString().includes('District')) {
		to.params.printEndpoint =
			`/api/pdf-reports/regional-reports/${to.params.year}/districts/${to.params.districtId}`
	} else if (to.name.toString().includes('Region')) {
		to.params.printEndpoint =
			`/api/pdf-reports/regional-reports/${to.params.year}/regions/${to.params.regionId}`
	}
	next()
}