
import { defineComponent, PropType } from 'vue'
import ChartPie from '@/components/temp-component/ChartPie.vue'
import AllProductionModal from '@/pages/Report/ui/AllProductionModal/AllProductionModal.vue'
import { ActivityCategory, ReportTarget } from '@/pages/Report/model/types/report'
import { getCSSVar, getLocaleString } from '@/shared/helpers'
import NotFoundBlock from '@/shared/ui/NotFoundBlock/NotFoundBlock.vue'
import { ProductType } from '@/entities/ProductTypes'
import { ActivityName, monthsInRusArray } from '@/shared/consts/common'

let queueOfColors = []

document.addEventListener('DOMContentLoaded', () => {
	queueOfColors = [
		getCSSVar('success-color'),
		getCSSVar('spirt-color'),
		getCSSVar('secondary-color'),
		getCSSVar('primary-color'),
		getCSSVar('alcohol-color'),
		getCSSVar('secondary-text-color'),
		getCSSVar('success-color'),
		getCSSVar('spirt-color'),
		getCSSVar('secondary-color'),
		getCSSVar('primary-color'),
		getCSSVar('alcohol-color'),
		getCSSVar('secondary-text-color')
	]
})

export default defineComponent({
	components: { NotFoundBlock, AllProductionModal, ChartPie },

	props: {
		data: Object as PropType<ActivityCategory>,
		typeOfProduct: String,
		type: String,
		target: Object as PropType<ReportTarget>,
		instantInit: Boolean
	},

	data() {
		return {
			selected: '',
			productionModal: false
		}
	},

	computed: {
		normalizeCommonVolume() {
			return getLocaleString((this.data.volume / 1000).toFixed(1))
		},
		ProductType() {
			return ProductType
		},
		ActionName() {
			return ActivityName
		},
		range(): string {
			const currentDate = new Date()
			const currentYear = currentDate.getFullYear().toString()
			const previousMonth = currentDate.getMonth()
			const previousMonthInRus = monthsInRusArray[previousMonth - 1]
			const yearFromRoute = this.$route.params.year.toString()
			let result: string

			if (currentYear === yearFromRoute) {
				if (currentDate.getMonth() > 1) {
					result = `В период январь-${previousMonthInRus} ${currentYear} года`
				} else {
					result = `За январь ${currentYear} года`
				}
			} else {
				result = `За ${yearFromRoute} год`
			}

			return result
		},
		// action() {
		// 	if (this.type === ActivityName.PRODUCTION) {
		// 		return 'произвели'
		// 	} else if (this.type === ActivityName.IMPORT) {
		// 		return 'импортировали'
		// 	} else if (this.type === ActivityName.EXPORT) {
		// 		return 'экспортировали'
		// 	} else {
		// 		return 'продали'
		// 	}
		// },
		productTypeInRus() {
			const productTypeName = this.$store.state.productTypes.normalizeTypes[this.typeOfProduct]?.type
				|| this.$store.state.productTypes.normalizeTypes[this.typeOfProduct]?.classifier
			if (productTypeName === ProductType.DISTILLATES) {
				return 'этилового спирта'
			} else if (productTypeName === ProductType.BEER) {
				return 'напитков брожения'
			} else if (productTypeName === ProductType.WITH_ALCOHOL) {
				return 'спиртосодержащей продукции'
			} else if (productTypeName === ProductType.WINE) {
				return 'вин'
			} else {
				return 'алкогольной продукции'
			}
		},
		actionInRus() {
			if (this.type === ActivityName.PRODUCTION) {
				return 'производство'
			} else if (this.type === ActivityName.IMPORT) {
				return 'импорт'
			} else if (this.type === ActivityName.EXPORT) {
				return 'экспорт'
			} else {
				return 'продажи'
			}
		},
		action2nd() {
			if (this.type === ActivityName.PRODUCTION) {
				return 'производимой'
			} else if (this.type === ActivityName.IMPORT) {
				return 'импортируемой'
			} else if (this.type === ActivityName.EXPORT) {
				return 'экспортируемой'
			} else {
				return 'продаваемой'
			}
		},
		action3nd() {
			if (this.type === ActivityName.PRODUCTION) {
				return 'производства'
			} else if (this.type === ActivityName.IMPORT) {
				return 'импорта'
			} else if (this.type === ActivityName.EXPORT) {
				return 'экспорта'
			} else {
				return 'продаж'
			}
		},
		// executor() {
		// 	if (this.type === ActivityName.PRODUCTION) {
		// 		return 'производителей'
		// 	} else if (this.type === ActivityName.IMPORT) {
		// 		return 'импортеров'
		// 	} else if (this.type === ActivityName.EXPORT) {
		// 		return 'экспортеров'
		// 	} else {
		// 		return 'продавцов'
		// 	}
		// },
		getSortedProductList() {
			const res = this.data.childs.map((item, i) => ({
				id: item.code,
				name: item.name,
				value: (item.volume / 1000).toFixed(1),
				percent: (item.volume / this.data.volume * 100).toFixed(1),
				color: queueOfColors[i],
				subgroups: item.childs?.map(child => ({
					id: child.code,
					name: child.name,
					amount: child.volume,
					percent: (child.volume / item.volume * 100).toFixed(1)
				})).sort((a, b) => b.amount - a.amount)
			}))

			res.sort((a, b) => b.value - a.value)

			return res
		}
	},

	methods: {
		getLocaleString,
		handleIn(e) {
			this.selected = e.data.name
		},
		handleOut() {
			this.selected = ''
		},
		openProductionModal() {
			this.productionModal = true
		}
	}
})
