
import { defineComponent, PropType } from 'vue'
import { SidebarItem } from '../model/types/sidebar'

export default defineComponent({
	props: {
		list: {
			type: Array as PropType<SidebarItem[]>,
			required: true
		}
	},

	data() {
		return {
			tooltipValue: ''
		}
	},

	computed: {
		sidebarIsOpened() {
			return this.$store.state.sidebar.isOpened
		},
		currentSection() {
			return this.$store.state.currentSection
		},
		currentSubSection() {
			return this.$store.state.currentSubSection
		}
	},

	watch: {
		currentSection(newValue) {
			this.tooltipValue = newValue

			setTimeout(() => {
				this.tooltipValue = ''
			}, 2500)
		}
	},

	methods: {
		toggleSidebar() {
			this.$store.dispatch('toggleSidebar')

			if (this.sidebarIsOpened) {
				this.$emit('opened')
			} else {
				this.$emit('closed')
			}
		}
	},

	mounted() {
		if (!this.sidebarIsOpened) {
			this.toggleSidebar()
		}

		this.$store.dispatch('checkSidebarPadding')
	},

	unmounted() {
		this.$store.dispatch('removeSidebarPadding')
	}
})
