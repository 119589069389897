import { getCSSVar } from '@/shared/helpers'
import { Fill, Stroke, Style } from 'ol/style'
import { Feature } from 'ol'
import { getDistrictById } from './helpers'

let primaryColor = getCSSVar('primary-color')
let defaultMapItemColor = getCSSVar('default-map-item-color')

document.addEventListener('DOMContentLoaded', () => {
	primaryColor = getCSSVar('primary-color')
	defaultMapItemColor = getCSSVar('default-map-item-color')
})

export const defaultStyle = (feature: Feature): Style => {
	const districtCode = feature.getProperties().federal_districts
	const districtColor = getDistrictById(districtCode).color

	return new Style({
		fill: new Fill({
			color: districtColor
		})
	})
}

export const defaultRegionsStyle = (): Style => new Style({
	fill: new Fill({
		color: defaultMapItemColor
	}),
	stroke: new Stroke({
		width: 2,
		color: '#fff'
	})
})

export const getSecondStyle = (colorIsPrimary = false, strokeWidth = 2): Style => {
	return new Style({
		fill: new Fill({
			color: colorIsPrimary ? primaryColor : defaultMapItemColor
		}),
		stroke: new Stroke({
			width: strokeWidth,
			color: '#fff'
		})
	})
}

export const hoverStyle = (): Style => {
	return new Style({
		fill: new Fill({
			color: primaryColor
		})
	})
}

export const hoverRegionStyle = (): Style => {
	return new Style({
		fill: new Fill({
			color: primaryColor
		}),
		stroke: new Stroke({
			width: 2,
			color: '#fff'
		})
	})
}