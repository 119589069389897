import { ActionTree } from 'vuex'
import { PrintSchema } from './state'
// noinspection ES6PreferShortImport
import { RootState } from '@/app/providers/store/types/RootState'
import { PrintMutations } from './mutations'

export const actions: ActionTree<PrintSchema, RootState> = {
	initPrintingListeners({ commit, state }) {
		if (!state.initialize) {
			window.addEventListener('beforeprint', () => {
				console.log('start')
				commit(PrintMutations.SET_PRINTING, true)
			})
			window.addEventListener('afterprint', () => {
				console.log('end')
				commit(PrintMutations.SET_PRINTING, false)
			})

			commit(PrintMutations.SET_INITIALIZE, true)
		}
	}
}