<template>
	<transition name="el-fade-in-linear">
		<div v-if="printEndpoint">
			<el-dropdown>
				<el-button
					:class="`${white ? 'default' : 'primary'} print`"
					:type="white ? 'default' : 'primary'"
					size="small"
				>
					Печать
					<el-icon class="el-icon--right">
						<span class="material-icons-round">expand_more</span>
					</el-icon>
				</el-button>
				<template #dropdown>
					<el-dropdown-menu>
						<el-dropdown-item @click="printPDF">PDF</el-dropdown-item>
						<el-dropdown-item @click="print">Печать</el-dropdown-item>
					</el-dropdown-menu>
				</template>
			</el-dropdown>

			<el-dialog
				:model-value="pageInDeveloping"
				center
				@close="pageInDeveloping = false"
			>
				<template #header>
					<h3 style="text-align:left;margin: 0;">Версия для печати в разработке</h3>
				</template>
				<img
					style="max-width: 100%"
					src="https://colombo.dn.ua/wp-content/uploads/2019/04/razrabotka.jpg"
					alt="Версия для печати в разработке"
				>
			</el-dialog>
		</div>
	</transition>
</template>

<script>
import { defineComponent } from 'vue'
// import printJS from 'print-js'
import { downloadPrintVersion, getPrintVersionInBlob } from '../api/api'
import printJS from 'print-js'

export default defineComponent({
	props: {
		white: {
			type: Boolean,
			default: () => false
		},
		printCustomEndpoint: {
			type: String,
			default: () => ''
		}
	},

	data() {
		return {
			pageInDeveloping: false
		}
	},

	computed: {
		printEndpoint() {
			return this.printCustomEndpoint || this.$route.params.printEndpoint
		}
	},

	methods: {
		async print() {
			this.$store.commit('setLoading', true)
			const blob = await getPrintVersionInBlob(this.printEndpoint)
			printJS(blob)
			this.$store.commit('setLoading', false)
		},
		async printPDF() {
			this.$store.commit('setLoading', true)
			await downloadPrintVersion(this.printEndpoint)
			this.$store.commit('setLoading', false)
		}
	}
})
</script>

<style
	scoped
	lang="scss"
>
.print {
	font-size: 0.75rem !important;
	font-weight: 400 !important;
	padding: 0.3125rem 1rem;
	border-radius: 0.25rem;

	@media print {
		display: none;
	}
}

.primary {
	border: 1px solid white !important;
}

.default {
	border: 1px solid var(--primary-color) !important;
	color: var(--primary-color);
}
</style>