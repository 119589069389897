import { Module } from 'vuex'
import { mutations } from './mutations'
import { RootState } from '@/app/providers/store/types/RootState'
import { BreadcrumbsSchema } from '../types/breadcrumbs'

export const state: BreadcrumbsSchema = {
	breadcrumbsList: [
		{
			path: '/',
			name: 'Главная',
			dropdown: undefined
		}
	]
}

const namespaced = true

export const breadcrumbsModule: Module<BreadcrumbsSchema, RootState> = {
	namespaced,
	state,
	mutations
}