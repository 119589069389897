import { transform } from 'ol/proj'
import { View } from 'ol'
import { ViewOptions } from 'ol/View'
import { Coordinate } from 'ol/coordinate'
import { Extent } from 'ol/extent'
import { easeOut } from 'ol/easing'

const russiaCoordinate: Coordinate = [105, 70]

const defaultViewForCountry: ViewOptions = {
	center: transform(russiaCoordinate, 'EPSG:4326', 'EPSG:3857'),
	zoom: 2.55,
	projection: 'EPSG:3857'
}
export const view = new View(defaultViewForCountry)

export const fitByExtent = (extent: Extent, duration = 300): void => {
	view.fit(extent, {
		padding: [32, 32, 32, 32],
		duration,
		easing: easeOut
	})
}

