import VectorSource from 'ol/source/Vector'
import GeoJSON from 'ol/format/GeoJSON'
import VectorLayer from 'ol/layer/Vector'
import { defaultStyle, getSecondStyle } from './styles'
import { Feature } from 'ol'
import { Extent } from 'ol/extent'
import { Layer } from 'ol/layer'
import { LocationType } from '@/pages/Report'

export const vectorSource = new VectorSource({
	url: '/map.geojson',
	format: new GeoJSON()
})

export const getExtentByDistrict = (districtId: number): Extent => {
	const districtFeatures = vectorSource.getFeatures().filter((feature: Feature) => {
		return Number(feature.getProperties().federal_districts) === districtId
	})

	const districtVectorSource = new VectorSource({
		features: districtFeatures,
		format: new GeoJSON()
	})

	return districtVectorSource.getExtent()
}

export const getExtentByRegion = (regionId: number): Extent => {
	const regionFeature = vectorSource.getFeatures().find((feature: Feature) => {
		return Number(feature.getProperties().id) === regionId
	})

	const regionVectorSource = new VectorSource({
		features: [regionFeature],
		format: new GeoJSON()
	})

	return regionVectorSource.getExtent()
}

export const getLayersByType = (type: LocationType): Layer[] => {
	let layer

	if (type === 'district') {
		layer = new VectorLayer({
			source: vectorSource,
			style: () => getSecondStyle()
		})
	} else if (type === 'region') {
		layer = new VectorLayer({
			source: vectorSource,
			style: () => getSecondStyle(true, 0)
		})
	} else {
		layer = new VectorLayer({
			source: vectorSource,
			style: defaultStyle
		})
	}

	return [
		layer
	]
}