
import { computed, defineComponent } from 'vue'
import { useRoute } from 'vue-router'

import { monthsInRusArray } from '@/shared/consts/common'

export default defineComponent({
	setup() {
		const { params } = useRoute()
		const currentDate = new Date()
		const currentYear = currentDate.getFullYear()
		const previousMonthInIndex = new Date().getMonth() - 1

		const range = computed(() => {
			let result: string

			if (currentYear.toString() === params.year.toString()) {
				if (currentDate.getMonth() > 1) {
					result = `период январь-${monthsInRusArray[previousMonthInIndex]} ${currentYear} г.`
				} else {
					result = `январь ${currentYear} г.`
				}
			} else {
				result = params.year.toString() + ' г.'
			}

			return result
		})

		return {
			range
		}
	}
})
