import { ShortProductCard } from '@/pages/Product'

interface ProductSearchState {
	tableData: ShortProductCard[]
}

export default {
	state: (): ProductSearchState => ({
		tableData: []
	}),

	mutations: {
		changeTableData(state: ProductSearchState, list: ShortProductCard[]): void {
			state.tableData = list.map(product => {
				if (!product.name) product.name = product.fullName
				return product
			})
		}
	}
}