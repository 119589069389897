import { AxiosRequestConfig } from 'axios'
import { $api } from '@/shared/api/api'

export async function getPrintVersionInBlob(url: string): Promise<string> {
	const config: AxiosRequestConfig = {
		responseType: 'arraybuffer',
		responseEncoding: 'binary',
		headers: {
			'Content-Type': 'application/pdf'
		}
	}
	const response = await $api.get(url, config)

	const pdfFile = new Blob([response.data], {
		type: 'application/pdf'
	})

	return URL.createObjectURL(pdfFile)
}

export async function downloadPrintVersion(url: string): Promise<void> {
	await $api({
		url,
		method: 'GET',
		responseType: 'blob'
	}).then((response) => {
		const href = URL.createObjectURL(response.data)

		const fileName = response.headers['content-disposition'].replace('attachment; filename=', '')
		const link = document.createElement('a')
		link.href = href
		link.setAttribute('download', fileName)
		document.body.appendChild(link)
		link.click()
		document.body.removeChild(link)
		URL.revokeObjectURL(href)
	})
}