import axios from 'axios'
import { ref } from 'vue'

interface ServerConfig {
	environment: string,
	yandexMetrikaId: string,
	api: {
		protocol: string,
		host: string,
		port: string
	}
}

export const serverConfig = ref<ServerConfig>(undefined)

const pathToConfig = process.env['NODE_ENV'] === 'dev-backend'
	? '/config-for-dev-backend.json'
	: '/server-sensitive-config.json'

const getServerConfig = async (): Promise<ServerConfig> => {
	const response = await axios.get<ServerConfig>(pathToConfig)
	return response.data
}

getServerConfig().then((config: ServerConfig) => {
	serverConfig.value = config
})