
import { defineComponent } from 'vue'
import { mapActions } from 'vuex'
import { OnBoarding } from '@/features/OnBoarding'

export default defineComponent({
	components: { OnBoarding },

	computed: {
		isSlideMode() {
			return this.$store.state.reports.viewMode === 'slides'
		}
	},

	data() {
		return {
			sliderHint: false
		}
	},


	methods: {
		toggle() {
			if (this.sliderHint) {
				this.close()
			}
			this.toggleMode()
		},
		close() {
			const now = new Date().getTime()
			localStorage.setItem('sliderHint', now.toString())
			this.sliderHint = false
		},
		checkHint() {
			if (localStorage.getItem('sliderHint')) {
				const lastHintCloseTime = +localStorage.getItem('sliderHint')
				const dayInMs = 36000000 * 24
				const now = new Date().getTime()
				if (lastHintCloseTime + dayInMs <= now) {
					localStorage.removeItem('sliderHint')
					this.sliderHint = true
				}
			} else {
				this.sliderHint = true
			}
		},
		...mapActions(['toggleMode'])
	},

	mounted() {
		this.checkHint()
	}
})
